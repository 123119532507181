<!--
 * @Descripttion: 
 * @version: 
 * @Author: Luochen
 * @Date: 2022-11-16 17:18:07
 * @LastEditors: Luochen
 * @LastEditTime: 2023-03-22 14:23:02
-->
<template>
  <div>
    <!-- <Header :url="require('@/assets/images/about.png')" /> -->
    <Header :banner="bannerList" />
    <Menu :menuList="menuList"></Menu>
    <div class="container">
      <div class="row">
        <div class="col">
          <div id="a1" class="defaultWord" v-html="aboutCon"></div>
        </div>
      </div>
      <div class="row" v-for="(item, inx) in lshList" :key="inx">
        <div class="col" v-if="inx == 0">
          <p id="a2" class="defaultWord mt20 pb10">{{ item.title }}</p>
          <div class="line"></div>
          <p class="defaultWord" v-if="item.zsmyhz != 0"><span class="name">终身名誉会长：</span>{{ item.zsmyhz.replace(/;/g,' &nbsp;')}}</p>
          <p class="defaultWord" v-if="item.gw != 0"><span class="name">顾问：</span>{{ item.gw.replace(/;/g,' &nbsp;') }}</p>
          <p class="defaultWord" v-if="item.hz != 0"><span class="name">会长：</span>{{ item.hz.replace(/;/g,' &nbsp;') }}</p>
          <p class="defaultWord" v-if="item.zxhz != 0"><span class="name">执行会长：</span>{{ item.zxhz.replace(/;/g,' &nbsp;') }}</p>
          <p class="defaultWord" v-if="item.fhz != 0"><span class="name">副会长：</span>{{ item.fhz.replace(/;/g,' &nbsp;') }}</p>
          <p class="defaultWord" v-if="item.msz != 0"><span class="name">秘书长：</span>{{ item.msz.replace(/;/g,' &nbsp;') }}</p>
          <p class="defaultWord" v-if="item.fmsz != 0"><span class="name">副秘书长：</span>{{ item.fmsz.replace(/;/g,' &nbsp;') }}</p>
          <p class="defaultWord mt20" v-if="item.xswy != 0"><span class="name">学术委员：</span>{{ item.xswy.replace(/;/g,' &nbsp;') }}</p>
          <p class="defaultWord" v-if="item.ls != 0"><span class="name">理事：</span>{{ item.ls.replace(/;/g,' &nbsp;') }}</p>
          <p class="defaultWord" v-if="item.js != 0"><span class="name">监事：</span>{{ item.js.replace(/;/g,' &nbsp;') }}</p>
        </div>
        <div class="col" v-else>
          <p class="defaultWord mt40 pb10">
            <span aria-controls="collapseExample" aria-expanded="false" class="cursor"
              :data-bs-target="'#collapseExample' + inx" data-bs-toggle="collapse">{{ item.title }} <img alt=""
                src="@/assets/images/bottom.png" style="width: 20px;margin-left: .5rem"></span>
          </p>
          <div class="line"></div>
          <div :id="'collapseExample' + inx" :class="['collapse',{'show':inx==0}]">
            <p class="defaultWord" v-if="item.zsmyhz != 0"><span class="name">终身名誉会长：</span>{{ item.zsmyhz.replace(/;/g,' &nbsp;') }}</p>
            <p class="defaultWord" v-if="item.gw != 0"><span class="name">顾问：</span>{{ item.gw.replace(/;/g,' &nbsp;') }}</p>
            <p class="defaultWord" v-if="item.hz != 0"><span class="name">会长：</span>{{ item.hz.replace(/;/g,' &nbsp;') }}</p>
            <p class="defaultWord" v-if="item.zxhz != 0"><span class="name">执行会长：</span>{{ item.zxhz.replace(/;/g,' &nbsp;') }}</p>
            <p class="defaultWord" v-if="item.fhz != 0"><span class="name">副会长：</span>{{ item.fhz.replace(/;/g,' &nbsp;') }}</p>
            <p class="defaultWord" v-if="item.msz != 0"><span class="name">秘书长：</span>{{ item.msz.replace(/;/g,' &nbsp;') }}</p>
            <p class="defaultWord" v-if="item.fmsz != 0"><span class="name">副秘书长：</span>{{ item.fmsz.replace(/;/g,' &nbsp;') }}</p>
            <p class="defaultWord mt20" v-if="item.xswy != 0"><span class="name">学术委员：</span>{{ item.xswy.replace(/;/g,' &nbsp;') }}</p>
            <p class="defaultWord" v-if="item.ls != 0"><span class="name">理事：</span>{{ item.ls.replace(/;/g,' &nbsp;') }}</p>
            <p class="defaultWord" v-if="item.js != 0"><span class="name">监事：</span>{{ item.js.replace(/;/g,' &nbsp;') }}</p>
          </div>
        </div>
      </div>
      <p id="a3" class="p_title_name">SIA发展历程</p>
      <div class="row">
        <div v-for="(item, inx) in fzlcList" :key="inx" class="col col-lg-3 col-sm-12">
          <div class="aboutOne cursor" data-bs-toggle="collapse" :data-bs-target="'#con' + inx" aria-expanded="false"
            :aria-controls="'con' + inx">
            <p class="oneTitle">{{item.time}}</p>
            <p class="twoTitle">{{item.title}}
            </p>
            <p class="threeTitle">{{item.content.substring(0,70)}}
            </p>
          </div>
          <div class="collapse" :id="'con' + inx" style="margin-top: -4rem;">
            <div class="aboutOne auto">
              <p class="threeTitle">{{ item.content.substring(70, item.content.length)}}
              </p>
            </div>

          </div>


        </div>
      </div>
      <p id="a4" class="p_title_name">合作伙伴</p>
      <div class="row justify-content-start">
        <div v-for="(item, inx) in hzhbList" :key="inx" class="cursor col" @click="$util.goLink(item)">
          <img :src="item.img" alt="" class="img-fluid mb30"
            style="max-width: 130px;">
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
export default {
  name: "about",
  data() {
    return {
      aboutCon: '',
      lshList: [],
      bannerList: [],
      fzlcList: [],
      hzhbList: [],
      menuList: [
        {
          id: 'a1',
          name: '关于我们',
        }, {
          id: 'a2',
          name: '理事会',
        }, {
          id: 'a3',
          name: '发展历程',
        }, {
          id: 'a4',
          name: '合作机构',
        },
      ]
    }
  },
  methods: {
    //关于我们banner
    get_two_banner() {
      this.$get('/api/get_two_banner', { type:1}).then(res => {
        this.bannerList = res.data.data;
      }).catch(err => {

      })
    },
    //关于我们介绍
    get_index_about_us() {
      this.$get('/api/get_index_about_us', {}).then(res => {
        this.aboutCon = res.data[0].about.replace(/\n/g, '<br>');
      }).catch(err => {

      })
    },
    //理事会数据
    get_index_lsh() {
      this.$get('/api/get_index_lsh', {}).then(res => {
        this.lshList = res.data;
      }).catch(err => {

      })
    },
   
    get_index_fzlc() {
      //SIA发展历程   
      this.$get('/api/get_index_fzlc', {}).then(res => {
        this.fzlcList = res.data;
      }).catch(err => {

      })
      //合作伙伴
      this.$get('/api/get_index_hzhb', {}).then(res => {
        this.hzhbList = res.data;
      }).catch(err => {

      })
    },
  },
  mounted() {
    this.get_index_about_us()
    this.get_index_lsh()
    this.get_index_fzlc()
    this.get_two_banner()
  }
}
</script>

<style lang="scss" scoped>
.aboutOne {
  width: 100%;
  background-color: #f0f0eb;
  padding: 1rem;
  box-sizing: border-box;
  color: #707070;
  text-align: left;
  overflow: hidden;
  margin-bottom: 1.5rem;
  min-width: 260px;

  &:hover {
    //height: auto;
  }

  .oneTitle {
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 0;
  }

  .twoTitle {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .threeTitle {}
}
@media (min-width: 1400px) {
  .aboutOne {
   height: 16rem;
  }
}
</style>
